import React from 'react';
import HelpBg from '../../assets/images/Help-BG.png'
import HelpBgM from '../../assets/images/Help-BG-m.png'
import Slider from "react-slick";
import { StaticQuery, graphql } from "gatsby"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
   dots: false,
   infinite: true,
   speed: 500,
   slidesToShow: 4,
   slidesToScroll: 4,
   responsive: [
      {
         breakpoint: 1024,
         settings: {
           slidesToShow: 4,
           slidesToScroll: 4,
           infinite: true,
           dots: false
         }
       },
       {
         breakpoint: 992,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 1,
           infinite: true,
           dots: false
         }
       },
       {
         breakpoint: 767,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 2,
           initialSlide: 2
         }
       },
       {
         breakpoint: 480,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       }
   ]
 };

const Help = () => (
  <section className="help-sec">
     <StaticQuery
            query={graphql`
               query {
                allWpPage(filter: {slug: {eq: "home"}}) {
                  edges {
                    node {
                      HomePage {
                        helpingHand {
                          title
                          subTitle
                          contentBlock {
                            backgroundColour
                            description
                            headingtitle
                            image
                            linkText
                            url
                          }
                        }
                      }
                    }
                  }
                }
               }
            `}
            render={data => {
                  return(
                     data &&
                     data.allWpPage &&
                     data.allWpPage.edges &&
                     data.allWpPage.edges[0] &&
                     data.allWpPage.edges[0].node &&
                     data.allWpPage.edges[0].node.HomePage &&
                     data.allWpPage.edges[0].node.HomePage.helpingHand &&
                     <React.Fragment>
                           <div className="help-back">
                              <img alt="helpbg" className="mobile-hidden" src={HelpBg} />
                              <img alt="helpbgm" className="desktop-hidden" src={HelpBgM} />
                           </div>
                           <div className="container">
                              <div className=" about-heading mt-0">
                                 <p>{data.allWpPage.edges[0].node.HomePage.helpingHand.title}</p>
                                 <h1 className="mb-5 text-light">{data.allWpPage.edges[0].node.HomePage.helpingHand.subTitle}</h1>
                              </div>
                              <div className="row">
                                 <Slider id="myaddCarousel" {...settings} className="services-items">
                                    {
                                        data &&
                                        data.allWpPage &&
                                        data.allWpPage.edges &&
                                        data.allWpPage.edges[0] &&
                                        data.allWpPage.edges[0].node &&
                                        data.allWpPage.edges[0].node.HomePage &&
                                        data.allWpPage.edges[0].node.HomePage.helpingHand &&
                                        data.allWpPage.edges[0].node.HomePage.helpingHand.contentBlock &&
                                        data.allWpPage.edges[0].node.HomePage.helpingHand.contentBlock.length>0&&
                                        data.allWpPage.edges[0].node.HomePage.helpingHand.contentBlock.map(
                                         prop => {
                                            return(
                                                <div className="item">
                                                   <div className="help-inner ">
                                                      <div dangerouslySetInnerHTML={{ __html: prop.image }} style={{backgroundColor:`${prop.backgroundColour}`}} className="help-icon"/>
                                                      <h6 className="text-light">{prop.headingtitle}</h6>
                                                      <p className="text-light mb-4">{prop.description}</p>
                                                      <a className="learn-link text-light " href={prop.url}>{prop.linkText}</a>
                                                   </div>
                                                </div>
                                            )
                                       })
                                    }
                                 </Slider>
                              </div>
                           </div>
                  </React.Fragment>
               )
            }}
         />
   
 </section>
)

export default Help