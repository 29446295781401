import React from 'react';
//import ls from "../../assets/images/ls.png"
//import s1 from "../../assets/images/s-1.png"
import { StaticQuery, graphql } from "gatsby"

const SuccessStories = () => (
    <section id="suces" className="sucess-bg">
   <div className="btm-right">
      <svg width="196" height="243" viewBox="0 0 196 243" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M147.564 30.6323L-183.95 507.327L-146.318 505.625L310.882 484.945C391.448 482.613 567.566 473.335 633.469 470.354L260.273 25.5342C246.006 8.75335 224.759 -0.5914 202.667 0.407882C180.583 1.59416 160.258 12.6319 147.564 30.6323Z" fill="#E0F5FE"/>
      </svg>
   </div>
   <figure className="s-leftbg">
      <svg width="380" height="740" viewBox="0 0 309 611" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M255.066 195.676L-642.866 -491V-417.662V473.338C-645.417 630.23 -642.866 973.568 -642.866 1102L255.066 415.324C288.957 389.054 309 348.554 309 305.5C308.636 262.446 288.957 221.946 255.066 195.676Z" fill="url(#paint0_linear)"/>
         <defs>
            <linearGradient id="paint0_linear" x1="-167.5" y1="-491" x2="-167.5" y2="1102" gradientUnits="userSpaceOnUse">
               <stop stop-color="#004179"/>
               <stop offset="1" stop-color="#19B8F8"/>
            </linearGradient>
         </defs>
      </svg>
   </figure>
   <div className="container">
   <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "home"}}) {
                  edges {
                    node {
                      HomePage {
                        successStories {
                          caseStudies {
                            activeUsers
                            buttonColour
                            buttonText
                            caseStudyLink
                            descriptionText
                            fundingRaised
                            monthlyIncome
                            title
                            partnerLogo {
                              sourceUrl
                            }
                            featuredImage {
                              sourceUrl
                            }
                          }
                          nextText
                          previousText
                          title
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
            return(
               data &&
                  data.allWpPage &&
                  data.allWpPage.edges &&
                  data.allWpPage.edges[0] &&
                  data.allWpPage.edges[0].node &&
                  data.allWpPage.edges[0].node.HomePage &&
                  data.allWpPage.edges[0].node.HomePage.successStories &&
               <React.Fragment>
                      <div id="myCarousel-sucess" className="carousel slide carousel-fade" data-ride="carousel">
                           <ol className="carousel-indicators">
                           {data &&
                              data.allWordpressPage &&
                              data.allWordpressPage.edges &&
                              data.allWordpressPage.edges[0] &&
                              data.allWordpressPage.edges[0].node &&
                              data.allWordpressPage.edges[0].node.HomePage &&
                              data.allWordpressPage.edges[0].node.HomePage.successStories &&
                              data.allWordpressPage.edges[0].node.HomePage.successStories.caseStudies&&
                              data.allWordpressPage.edges[0].node.HomePage.successStories.caseStudies.length>0&&
                              data.allWordpressPage.edges[0].node.HomePage.successStories.caseStudies.map(
                                 (prop,key) => {
                                       return(
                                          <li data-target="#myCarousel" data-slide-to={key} class={`${key === 0?'active':''}`}></li>
                                       )
                                 })}
                           </ol>
                           <div className="carousel-inner mobile-reversecontent">
                              {
                                  data &&
                                  data.allWordpressPage &&
                                  data.allWordpressPage.edges &&
                                  data.allWordpressPage.edges[0] &&
                                  data.allWordpressPage.edges[0].node &&
                                  data.allWordpressPage.edges[0].node.HomePage &&
                                  data.allWordpressPage.edges[0].node.HomePage.successStories &&
                                  data.allWordpressPage.edges[0].node.HomePage.successStories.caseStudies&&
                                  data.allWordpressPage.edges[0].node.HomePage.successStories.caseStudies.length>0&&
                                  data.allWordpressPage.edges[0].node.HomePage.successStories.caseStudies.map(
                                       (prop,key) => {
                                          return(
                                             <div className={`item carousel-item ${key===0?'active':''}`}>
                                             <div className="row">
                                                <div className="col-lg-6 text-center">
                                                   <div className="img-box">
                                                      <img src={prop.featuredImage.sourceUrl} alt=""/>    
                                                   </div>
                                                </div>
                                                <div className="col-lg-6">
                                                   <p className="sub-heading mt-5">Success stories</p>
                                                   <figure className="mt-5 mt-5">
                                                      <img src={prop.partnerLogo.sourceUrl} alt=""/>
                                                   </figure>
                                                   <h3 className="info-title">{prop.title}</h3>
                                                   <hr />
                                                   <div className="d-flex s-info justify-content-between">
                                                      <span dangerouslySetInnerHTML={{ __html: prop.activeUsers }}>
                                                        
                                                      </span>
                                                      <span dangerouslySetInnerHTML={{ __html: prop.fundingRaised }}>
                                                        
                                                      </span>
                                                      <span dangerouslySetInnerHTML={{ __html: prop.monthlyIncome }}>
                                                       
                                                      </span>
                                                   </div>
                                                   <hr />
                                                   <p className="sucess-disp">{prop.descriptionText}</p>
                                                   <div className="c-btn mobile-hidden">   
                                                      <button onclick="location.href = 'case-study.html'; return false;" className="btn-bg case-btn mb-3">{prop.buttonText}</button>
                                                </div>
                                                </div>
                                             </div>
                                          </div>
                                          )
                                       })
                              }
                           </div>
                           <a className="carousel-control left carousel-control-prev" href="#myCarousel-sucess" data-slide="prev">{data.allWpPage.edges[0].node.HomePage.successStories.previousText }</a> 
                           <a className="carousel-control right carousel-control-next" href="#myCarousel-sucess" data-slide="next">{data.allWpPage.edges[0].node.HomePage.successStories.nextText } </a>
                        </div>
                        <div className="c-btn desktop-hidden">   
                           <button onclick="location.href = 'case-study.html'; return false;" class="btn-bg case-btn mb-3">Case Study </button>
                        </div>
               </React.Fragment>
            )
         }}/>
      
   </div>
 </section>
)

export default SuccessStories;