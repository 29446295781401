import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import solutionbg from "../../assets/images/Solutions-BG.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
   dots: false,
   infinite: true,
   speed: 500,
   slidesToShow: 4,
   slidesToScroll: 4,
   responsive: [
      {
         breakpoint: 1024,
         settings: {
           slidesToShow: 4,
           slidesToScroll: 4,
           infinite: true,
           dots: false
         }
       },
       {
         breakpoint: 992,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 1,
           infinite: true,
           dots: false
         }
       },
       {
         breakpoint: 767,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 2,
           initialSlide: 2
         }
       },
       {
         breakpoint: 480,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       }
   ]
 };

const WhatWeDo = () => (
  <section  className="about-sec  mb-5">
    <div className="about-back">
       <img alt="solutionbg" src={solutionbg} />
    </div>
    <div className="container">
      <StaticQuery
            query={graphql`
               query {
                  wpMenu(slug: {eq: "header-menu"}) {
                     name
                     slug
                     menuItems {
                       nodes {
                         label
                         url
                         title
                         childItems {
                           nodes {
                             url
                             childItems {
                               nodes {
                                 url
                                 label
                                 description
                                 cssClasses
                                 parentId
                                 databaseId
                                 HeaderMenuIcon {
                                   fieldGroupName
                                 }
                               }
                             }
                             label
                             description
                             cssClasses
                             parentDatabaseId
                           }
                         }
                         parentId
                         parentDatabaseId
                         linkRelationship
                         id
                         cssClasses
                       }
                     }
                   }
                   allWpPage(filter: {slug: {eq: "home"}}) {
                     edges {
                       node {
                         HomePage {
                           whatWeDo {
                             menus {
                               title
                               menuName
                             }
                             subTitle
                             title
                             description
                           }
                         }
                       }
                     }
                   }
               }
            `}
            render={data => {
               return(
                  data &&
                  data.allWpPage &&
                  data.allWpPage.edges &&
                  data.allWpPage.edges[0] &&
                  data.allWpPage.edges[0].node &&
                  data.allWpPage.edges[0].node.HomePage &&
                  data.allWpPage.edges[0].node.HomePage.whatWeDo &&
                  <React.Fragment>
                       <div style={{position:"relative",zIndex:9}} className="row about-heading">
                           <div className="col-md-6">
                              <p>{data.allWpPage.edges[0].node.HomePage.whatWeDo.subTitle}</p>
                              <h1>
                                 {data.allWpPage.edges[0].node.HomePage.whatWeDo.title}
                              </h1>
                           </div>
                           <div className="col-md-5 d-flex align-items-center">
                              <p className="about-disp mb-0">
                                 {data.allWpPage.edges[0].node.HomePage.whatWeDo.description}
                              </p>
                           </div>
                        </div>
                        <div className="row">
                           <Slider id="myaddCarousel" {...settings} className="services-items">
                              {
                                 data.wpMenu &&
                                 data.wpMenu.menuItems &&
                                 data.wpMenu.menuItems.nodes &&
                                 data.wpMenu.menuItems.nodes.length>0 &&
                                 data.wpMenu.menuItems.nodes.map(
                                    prop => {
                                    return(
                                       prop && prop.id==="cG9zdDo1OQ==" && prop.childItems && prop.childItems.nodes.length>0?(
                                          prop.childItems.nodes.map((child, key) => {
                                            console.log("Children", child);
                                             return(
                                                <div key={key} className="item">
                                                <div className="bg-white services p-4 mt-4">
                                                  {child.label === "Mobile Development"?<h5 className="color-green mb-4">{child.label}</h5>:null}
                                                  {child.label === "Web Development"?<h5 className="text-sky mb-4">{child.label}</h5>:null}
                                                  {child.label === "UX/UI Design"?<h5 className="text-org mb-4">{child.label}</h5>:null}
                                                  {child.label === "AI/ML"?<h5 className="text-blue mb-4">{child.label}</h5>:null}
                                                  <ul className="pl-0 servoces-items mb-0">
                                                     {
                                                        child.childItems.nodes.map((children, keychildren)=>{
                                                                  return(
                                                                     <React.Fragment>
                                                                          <li key={keychildren} className="d-flex align-items-center justify-content-between">
                                                                              <div className="d-flex align-items-center">
                                                                                <img style={{height:"25px", width:"20px"}} src={`${children.cssClasses[0]}.svg`} alt="icon"/>
                                                                                 {/*<svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M6.6839 24.6858C3.11197 24.6647 0.157349 17.1648 0.157349 13.3453C0.157349 7.10623 4.70774 5.74032 6.46143 5.74032C7.25174 5.74032 8.09563 6.05954 8.83996 6.34203C9.36046 6.53908 9.89877 6.7425 10.1982 6.7425C10.3774 6.7425 10.7997 6.56946 11.1725 6.41763C11.9676 6.09204 12.9571 5.68738 14.1093 5.68738C14.1113 5.68738 14.1141 5.68738 14.1161 5.68738C14.9765 5.68738 17.5851 5.88158 19.1533 8.30406L19.5207 8.87188L18.992 9.28223C18.2367 9.86842 16.8586 10.9377 16.8586 13.0558C16.8586 15.5645 18.4193 16.5292 19.1691 16.9932C19.5001 17.1981 19.8427 17.4092 19.8427 17.8711C19.8427 18.1727 17.5026 24.6484 14.1044 24.6484C13.2729 24.6484 12.6852 24.3913 12.1667 24.1646C11.6421 23.9351 11.1896 23.7374 10.4419 23.7374C10.0628 23.7374 9.58354 23.9217 9.07611 24.1173C8.38269 24.3835 7.59782 24.6858 6.70724 24.6858H6.6839Z" fill="#666666"/>
                                                                                    <path d="M14.4693 0C14.5578 3.28224 12.2756 5.5593 9.99618 5.41649C9.62057 2.79715 12.2754 0 14.4693 0Z" fill="#666666"/>
                                                                                 </svg>*/}
                                                                                 <p className="ml-3 mb-0 "> {children.label}</p>
                                                                              </div>
                                                                              <span>
                                                                                 <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M0.899963 0.800049L5.27496 5.17505L0.899963 9.55005" stroke="#48B774" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                 </svg>
                                                                              </span>
                                                                           </li>
                                                                           <hr />
                                                                     </React.Fragment>
                                                                  )
                                                        })
                                                     }
                                                  </ul>
                                               </div>
                                            </div>
                                             )
                                          })
                                          
                                       ):null
                                      
                                   )
                                 })}
                           </Slider>
                        </div>
                  </React.Fragment>
               )
            }}
         />
   </div>
 </section>
)

export default WhatWeDo;