import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout";
import Banner from "../components/home/banner";
import Collaboration from "../components/home/collaboration";
import WhatWeDo from "../components/home/whatwedo";
import SuccessStories from "../components/home/successstories";
import Help from "../components/home/help";
import WhyTeamlance from "../components/home/whyteamlance";
import Testimonial from "../components/home/testimonial";
//import Image from "../components/image";
import SEO from "../components/seo";

const IndexPage = (props) => (
  <Layout {...props}>
    <SEO title="Home" />
    <div class="col-12 wrapper main-bggrey p-0">
       <div class="col-12 inner-page-content home-inner  px-0">
          <Banner/>
          <Collaboration/>
          <WhatWeDo/>
       </div>
    </div>
    <SuccessStories/>
    <Help/>
    <WhyTeamlance/> 
    <Testimonial/>
  </Layout>
)

export default IndexPage
