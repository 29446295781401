import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const Banner = () => (
 <section className=" home-banner">
    <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "home"}}) {
                  edges {
                    node {
                      HomePage {
                        headerSection {
                          bgImage
                          buttonText
                          buttonUrl
                          content
                          title
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
            return (
              data && data.allWpPage &&
              data.allWpPage.edges &&
              data.allWpPage.edges[0] &&
              data.allWpPage.edges[0].node &&
              data.allWpPage.edges[0].node.HomePage &&

              <div className="container">
               <div className="row">
                  <div className=" line-up d-flex flex-column sectiontop-pd  bnr-txtbg banner-sec1">
                     <div className="banner-text my-auto col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                        <h3 className="">
                            {data.allWpPage.edges[0].node.HomePage.headerSection.title}
                        </h3>
                        <figure>
                           <svg width="270" height="28" viewBox="0 0 270 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 15.0001C1 15.0001 146 -13 268 24" stroke="#19B8F8" stroke-width="8" stroke-linejoin="round"/>
                           </svg>
                        </figure>
                        <p>{data.allWpPage.edges[0].node.HomePage.headerSection.content}</p>
                        <div className="mobile-center">  
                           <button className="estimate-btn btn btn-bg btn-design text-white">
                               {data.allWpPage.edges[0].node.HomePage.headerSection.buttonText}
                           </button>
                        </div>
                     </div>
                  </div>
                  <div className=" bg-lt pl-imgbanner banner-sec2">
                     <div dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.HomePage.headerSection.bgImage }} className="d-flex justify-content-center align-items-center  sectiontop-pd sectionbtm-pd"></div>
                  </div>
               </div>
            </div>
           )
         }}
     />
</section>
)

export default Banner;