import React from 'react'
import { StaticQuery, graphql } from "gatsby"


const Collaboration = () => (
  <section class="colaborate-homesec mt-4 py-2 sectiontop-pd sectionbtm-pd">    
    <div class="container">
      <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "home"}}) {
                  edges {
                    node {
                      HomePage {
                        ourPartners {
                          ourPartners {
                            logo {
                              altText
                              sourceUrl
                            }
                          }
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
              return(
                 <React.Fragment>
                       <div class="colaborate-homeinner my-5 desktop-hidden">
                         <div class="colab row  d-flex justify-content-between  flex-wrap p-0 border-radius24">
                           {data &&
                              data.allWpPage &&
                              data.allWpPage.edges &&
                              data.allWpPage.edges[0] &&
                              data.allWpPage.edges[0].node &&
                              data.allWpPage.edges[0].node.HomePage.ourPartners &&
                              data.allWpPage.edges[0].node.HomePage.ourPartners.ourPartners&&
                              data.allWpPage.edges[0].node.HomePage.ourPartners.ourPartners.length>0&&
                              data.allWpPage.edges[0].node.HomePage.ourPartners.ourPartners.map(
                                 prop => {
                                      return(
                                       <div class="colab-grid col-md-3 col-sm-3 py-1 px-2 p-5">
                                           <img src={prop.logo.sourceUrl} alt="colab img" />
                                        </div>
                                      )
                                 })}
                           </div>
                        </div>
                        <div class="colaborate-homeinner my-5 mobile-hidden">
                           <div class="colab row  d-flex justify-content-between  flex-wrap p-0 border-radius24">
                              {data &&
                                 data.allWpPage &&
                                 data.allWpPage.edges &&
                                 data.allWpPage.edges[0] &&
                                 data.allWpPage.edges[0].node &&
                                 data.allWpPage.edges[0].node.HomePage.ourPartners &&
                                 data.allWpPage.edges[0].node.HomePage.ourPartners.ourPartners&&
                                 data.allWpPage.edges[0].node.HomePage.ourPartners.ourPartners.length>0&&
                                 data.allWpPage.edges[0].node.HomePage.ourPartners.ourPartners.map(
                                    prop => {
                                       return(
                                          <div class="colab-grid col-md-3 col-sm-3 py-1 px-2 p-5">
                                                    <img src={prop.logo.sourceUrl} alt="colab img" />
                                          </div>
                                       )
                                    })}
                           </div>
                     </div>
                 </React.Fragment>
              )
         }}
        />
    </div>
    <div class="colab-grapips">
       <svg width="242" height="276" viewBox="0 0 242 276" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M57.8278 55.626L494.395 438.443L457.461 445.854L8.73892 535.9C-70.0166 553.041 -243.185 586.454 -307.866 599.434L-52.7909 77.8241C-42.9859 58.101 -24.615 43.914 -2.93224 39.5629C18.7873 35.3953 41.1726 41.2128 57.8278 55.626Z" fill="url(#paint0_linear)"/>
          <defs>
             <linearGradient id="paint0_linear" x1="446.354" y1="199.041" x2="-355.907" y2="360.032" gradientUnits="userSpaceOnUse">
                <stop stop-color="#004179"/>
                <stop offset="1" stop-color="#19B8F8"/>
             </linearGradient>
          </defs>
       </svg>
    </div>
    <div class="colab-right">
       <svg width="304" height="611" viewBox="0 0 304 611" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M53.9342 195.676L951.866 -491V-417.662V473.338C954.417 630.23 951.866 973.568 951.866 1102L53.9342 415.324C20.0431 389.054 0 348.554 0 305.5C0.36442 262.446 20.0431 221.946 53.9342 195.676Z" fill="#E0F5FE"/>
       </svg>
    </div>
 </section>
)

export default Collaboration