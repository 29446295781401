import React from 'react';
import ts1 from "../../assets/images/ts1.png"
import video from "../../assets/images/video.png"
import { StaticQuery, graphql } from "gatsby"

const Testimonial = () => {
   return(
   <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "global-footer"}}) {
                  edges {
                    node {
                      slug
                      FooterContent {
                        copyrightText
                        footerMenu1
                        footerMenu2
                        footerMenu3
                        footerMenu4
                        clientReviews {
                          reviews {
                            ... on WpSocialReview {
                              id
                              content
                              SocialReviews {
                                comments
                                designation
                                company
                                source
                                sourceUrl
                                videoImage {
                                 sourceUrl
                                }
                              }
                              title
                            }
                          }
                          title
                        }
                        footerMenus {
                          ... on WpPage_Footercontent_FooterMenus_FooterMenus {
                            fieldGroupName
                            menu {
                              fontFamily
                              fontSize
                              linkColor
                              linkHoverColor
                              menuName
                            }
                          }
                        }
                        letSHaveAChat {
                          block {
                            description
                            link
                            title
                          }
                          title
                        }
                        ourLocations {
                          location {
                            address
                            buttonText
                            flag {
                              sourceUrl
                              title
                            }
                            locationLink
                            mapImage
                            name
                          }
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
            return(
               data && 
               data.allWpPage &&
               data.allWpPage.edges &&
               data.allWpPage.edges[0] &&
               data.allWpPage.edges[0].node &&
               data.allWpPage.edges[0].node.FooterContent &&
               data.allWpPage.edges[0].node.FooterContent.clientReviews&&
               <section id="testimonial">
                 <div className="container py-4">
                    <div className="row py-xl-5 py-4">
                       <div className="col-md-6">
                          <h2 className="test-heading mb-5">
                             {data.allWpPage.edges[0].node.FooterContent.clientReviews.title}
                          </h2>
                          <div className="testm-slider">
                             <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                   {data.allWpPage.edges[0].node.FooterContent.clientReviews
                                   &&data.allWpPage.edges[0].node.FooterContent.clientReviews.reviews.map((data,key)=>{
                                       return(
                                          <div key={key} className={`item carousel-item ${key===0?'active':""}`}>
                                             <div className="overview">
                                                <div className="d-flex align-items-center">
                                                   <span>
                                                      <h1 className="mt-2">{data.SocialReviews.company}</h1>
                                                   </span>
                                                   <h6 className="mb-0 ml-3">{data.title}</h6>
                                                </div>
                                                <p>{data.SocialReviews.designation}</p>
                                             </div>
                                             <p className="testimonial">{`“${data.SocialReviews.comments}”`}</p>
                                             <a className="con-link" href={data.SocialReviews.sourceUrl?data.SocialReviews.sourceUrl:"/"}>{data.SocialReviews.source}</a>
                                           </div>
                                       )
                                   })}
                                </div>
                                <a className="carousel-control left carousel-control-prev" href="#myCarousel" data-slide="prev">
                                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.125 10H16.875" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                   </svg>
                                </a>
                                <a className="carousel-control right carousel-control-next" href="#myCarousel" data-slide="next">
                                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.125 10H16.875" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                   </svg>
                                </a>
                             </div>
                          </div>
                       </div>
                       <div className="col-md-6">
                          <div className="video-sec">
                             <img src={video} alt="video" />
                          </div>
                       </div>
                    </div>
                 </div>
              </section>
             )
         }}
      />
   )
   
}

export default Testimonial;