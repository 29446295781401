import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const WhyTeamlance = () => (
  <section id="whyus">
    <div className="why-triangle">
        <svg width="489" height="212" viewBox="0 0 489 212" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M490 67.5004L366.13 17.6306L7.49989 116.5L490 141.5L490 67.5004Z" fill="#F4F8FF"/>
        </svg>
    </div>
    <div className="container pt-5">
        <div className="row  d-flex align-items-center" >
          <StaticQuery
            query={graphql`
              query {
                allWpPage(filter: {slug: {eq: "home"}}) {
                  edges {
                    node {
                      HomePage {
                        whyTeamlance {
                          description
                          title
                          subTitle
                          contentBlock {
                            backgroundColour
                            image
                            textDescription
                            cssClass
                          }
                        }
                      }
                    }
                  }
                }
               }
            `}
            render={data => {
                return(
                    data &&
                    data.allWpPage &&
                    data.allWpPage.edges &&
                    data.allWpPage.edges[0] &&
                    data.allWpPage.edges[0].node &&
                    data.allWpPage.edges[0].node.HomePage &&
                    data.allWpPage.edges[0].node.HomePage.whyTeamlance &&
                    <React.Fragment>
                        <div className="col-md-6 about-heading mt-0">
                            <p>{data.allWpPage.edges[0].node.HomePage.whyTeamlance.title}</p>
                            <h1 className="mb-3">{data.allWpPage.edges[0].node.HomePage.whyTeamlance.subTitle}</h1>
                            <p className="why-disp mb-5">{data.allWpPage.edges[0].node.HomePage.whyTeamlance.description}</p>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                {data &&
                                    data.allWpPage &&
                                    data.allWpPage.edges &&
                                    data.allWpPage.edges[0] &&
                                    data.allWpPage.edges[0].node &&
                                    data.allWpPage.edges[0].node.HomePage &&
                                    data.allWpPage.edges[0].node.HomePage.whyTeamlance &&
                                    data.allWpPage.edges[0].node.HomePage.whyTeamlance.contentBlock &&
                                    data.allWpPage.edges[0].node.HomePage.whyTeamlance.contentBlock.length>0&&
                                    data.allWpPage.edges[0].node.HomePage.whyTeamlance.contentBlock.map(
                                        prop => {
                                            return(
                                                <div className="our-features col-sm-6 text-center">
                                                    <figure dangerouslySetInnerHTML={{ __html: prop.image }} style={{backgroundColor:prop.backgroundColour}} className="featured-icons "/>
                                                    <h6>{prop.textDescription}</h6>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                      </div>
                </React.Fragment>
               )
            }}
           />
        </div>
    </div>
  </section>
)

export default WhyTeamlance;